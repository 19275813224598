import styled from "styled-components";
import { Link as LinkR } from 'react-router-dom'
import {Link as LinkS} from 'react-scroll'

export const Nav = styled.nav`
   background: ${({ scrollNav }) => (scrollNav ? '#000' : 'transparent')};
   height:75px;
   margin-top: -75px;
   display: flex;
   justify-content: center;
   align-items: center;
   font-size: 22px;
   position: sticky;
   top: 0;
   z-index: 10;
   /* width: 100%; */
   transition: 150ms ease-in-out;


   

   


   @media screen and (max-width: 960px){
       transition: 0.8s all ease;
   }


`

export const NavbarContainer = styled.div`
   display:flex;
   justify-content: space-between;
   height: 80px;
   z-index: 1;
   width: 100%;
   padding: 0 22px;
   max-width: 1200px;


`;

export const NavLogo = styled(LinkS)`
  
   color: #fff;
   justify-self: flex-start;
   


   cursor: pointer;
   font-size: 1.5rem;
   display: flex;
   align-items: center;
   margin-left: 24px;
   font-weight: bold;
   text-decoration: none;
   img{
      width: 150px;
      @media screen and (max-width:798px) {
         width: 120px;
      
   }
   
      
   }
   

`;

export const MobileIcon = styled.div`
   display: none;

   @media screen and (max-width: 1115px) {
       display: block;
       position: absolute;
       top: 25px;
       right: 20px;
       transition: translate(-100%, 60%);
       font-size: 1.8rem;
       cursor: pointer;
       color:#fff;
       
    }

`
export const NavMenu = styled.ul`
   display: flex;
   align-items: center;
   list-style: none;
   text-align: center;
   margin-right: -22px;
   padding-top: 15px;
  

 
   

   @media screen and (max-width:1115px) {
       display: none;
    }

`

export const NavItem = styled.li`
   height: 80px;

   


`

export const NavLinks = styled(LinkS)`
   color: #fff;
   display: flex;
   align-items: center;
   text-decoration: none;
   padding: 0 1rem;
   height: 100%;
   cursor: pointer;
   justify-content: center;
  
   /* font-weight: bold; */

   
   &:hover{
      color: #01bf71;
      border-bottom: 3px solid #01bf71;
   }
   &:focus{
      /* color:#01bf71; */
   }

`;

export const NavBtn = styled.nav`
   display: flex;
   align-items: center;

   @media screen and (max-width: 1115px) {

      display: none;
      
   }


`
export const NavBtnLink = styled(LinkS)`
   border-radius: 50px;
   background: #01bf71;
   white-space: nowrap;
   padding: 10px 22px;
   color:#010605;
   font-size: 18px;
   outline: none;
   border: none;
   cursor: pointer;
   transition: all 0.2s ease-in-out;
   text-decoration: none;
   margin-left: 30px;


   &:hover{
      transition: all 0.2s ease-in-out;
      background: #fff;
      color: #010605;

   }
   &:focus{
     outline:2px solid #fff;
   }
   


`




import React, { useState } from "react";
import {
  HeroContainer,
  HeroBg,
  ImageBg,
  HeroContent,
  HeroH1,
  HeroBtnWrapper,
  HeroP,
  ArrowForward,
  ArrowRight,
} from "./HeroElements.js";
import Video from "../../videos/video.mp4";
// import { FaArrowRight } from "react-icons/fa";
import { Button } from "../ButtonElement";
import "./styles.css"

const HeroSection = () => {
  const [hover, setHover] = useState(false);

  const handleEnter = (e)=>{
    let keycode = (e.keyCode ? e.keyCode : e.which);
    if(keycode == '13'){
      document.activeElement.click()
    }
  }

  const onHover = () => {
    setHover(!hover);
  };
  return (
    <HeroContainer id="home">
      <HeroBg>
        <ImageBg autoPlay loop muted src={Video} type="video/mp4" />
      </HeroBg>
      <HeroContent>
        <HeroH1>PLUG 'N' PLAY </HeroH1> <p className= "para">SOLUTION</p>
        <HeroP>
          Protect your business against cyber attacks by building a
          comprehensive penetration testing programme in place. FulSec's
          creative pentest approach focuses on finding vulnerabilities in your
          network, cloud and web applications that others miss. The experts at
          FulSec provide you a managed cyber security service for Governance,
           Risk and Compliance (GRC). All this while being a scalable and low
          cost solution.
        </HeroP>
        <HeroBtnWrapper>
          <Button to="signup" onMouseEnter={onHover} onMouseLeave={onHover} tabIndex="0"  onKeyPress = {handleEnter}>
            Get started {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;

import React from "react";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import styled from "styled-components";

const PreviousBtn = (props) => {
  console.log(props);
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <FaArrowLeft />
    </div>
  );
};
const NextBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <FaArrowRight />
    </div>
  );
};

const ServicesInfo = () => {
  const handleEnter = (e) => {
    let keycode = e.keyCode ? e.keyCode : e.which;
    if (keycode == "13") {
      document.activeElement.click();
    }
  };

  let settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    // speed: 1000,
    autoplaySpeed: 5000,
    cssEase: "linear",
  };
  return (
    <Section>
      <div className="testimonial">
        <Slider
          prevArrow={<PreviousBtn />}
          nextArrow={<NextBtn />}
          {...settings}
        >
          <Card />
          <Card2 />
          <Card3 />
          <Card4 />
          <Card5 />
          <Card6 />
          <Card7 />
        </Slider>
      </div>
    </Section>
  );
};

const Card = () => {
  return (
    <div className="content" id="small">
      <h2>What is VAPT </h2>
      <div className="content-para">
        <p>
          Vulnerability Assessment & Penetration Testing consists of a broad
          range of services which address cyber security vulnerabilities of any
          organization’s IT domain. Penetration testing is where VAPT experts
          perform closed door experiments on the security of the IT
          infrastructure and ensuring that it has no soft spots for cyber
          attacks. What this means to companies is a peace of mind against
          external cyber security threats.
        </p>
      </div>
    </div>
  );
};
const Card2 = () => {
  return (
    <div className="content" id="small">
      <h2>Why Fulsec is the right VAPT provider for you </h2>
      <div className="content-para">
        <p>
          FulSec offers robust all round offensive security services with the
          capability to thwart any risk and also provide the necessary support.
          As ISO 27001, and ISO 9001 certified, FulSec can be trusted to meet
          your target requirements with a scalable low cost model. Our testing
          methods are rigorous, following the industry leading standards for
          VAPT.
        </p>
      </div>
    </div>
  );
};
const Card3 = () => {
  return (
    <div className="content">
      <h2>VAPT: Web Application Pentest </h2>
      <div className="content-para">
        <p>
          We follow only the highest industry standards – OWASP Top 10 for web
          application to test your networks in a safe environment. This enables
          our experts to detect threats and neutralize them with detailed
          reports of the nature and impact it can have on your business. We
          ensure that there are no false positives in our testing. Post testing
          remediation reporting gives you a detailed walk through on the
          vulnerabilities and implement of corrective remediation.
        </p>

        <ul className="list">
          <li>Industry Standards: OWASP Top 10 (web application)</li>
          <li>Checklist-based Testing</li>
          <li>
            Minimal False Positives 
          </li>
          <li>Remediation-oriented Reporting</li>
        </ul>
      </div>
    </div>
  );
};
const Card4 = () => {
  return (
    <div className="content">
      <h2>VAPT: Mobile Application Pentest </h2>
      <div className="content-para">
        <p>
          FulSec utilizes proprietary tools that follow the OWASP Top 10 Mobile
          App Standards to understand your app vulnerabilities and the risks
          they pose to your business. We propose a customized approach to
          utilize the latest techniques to tackle any challenge in the future.
          Our standard procedures ensure that you are not bothered by any false
          positives while our remediation oriented reporting at the end provides
          you with a positive remediation.
        </p>

        <ul className="list">
          <li>Industry Standards: OWASP Top 10 (mobile application)</li>
          <li>Checklist-based Testing</li>
          <li>Minimal False Positives</li>
          <li>Remediation-oriented Reporting</li>
        </ul>
      </div>
    </div>
  );
};
const Card5 = () => {
  return (
    <div className="content">
      <h2>VAPT: Cloud Services Pentest </h2>
      <div className="content-para">
        <p>
          We strive to not just be a standard process on-premise environment.
          Our methodology is specific to cloud, which requires expertise
          different from standard penetration testing. Examining configurations
          that are native to cloud – encryption, applications, APIs,, storage,
          databases etc. Our strategy focuses on assessing the strengths and
          weaknesses to improve your overall security posture and prevent cyber
          attacks that cripple entire cloud systems. Standard minimal false
          positives and remediation oriented reporting
          at the end provides you with a positive remediation.
        </p>

        <ul className="list">
          <li>
            Industry Standards: ISO-27001 / ISO-27002, SOC II, HIPAA, GDPR
          </li>
          <li>Checklist-based Testing</li>
          <li>Minimal False Positives</li>
          <li>Remediation-oriented Reporting</li>
        </ul>
      </div>
    </div>
  );
};
const Card6 = () => {
  return (
    <div className="content">
      <h2>VAPT: Network Pentest </h2>
      <div className="content-para">
        <p>
          Network infrastructure is critical to your business’ operations.
          FulSec ensures the integrity of your physical and virtual networks.
          Our network security system focuses on strengthening your network from
          the perimeter to the core. A checklist based testing to ensure that
          your network is future proofed with any and all red flags double
          verified for minimal false positives. To end the program, our
          remediation oriented reporting gives you a detailed analysis of the
          program with a positive corrective remediation.
        </p>

        <ul className="list">
          <li>Industry Standards: NIST Cybersecurity Framework</li>
          <li>Checklist-based Testing</li>
          <li>Minimal False Positives</li>
          <li>Remediation-oriented Reporting</li>
        </ul>
      </div>
    </div>
  );
};
const Card7 = () => {
  return (
    <div className="content" id="medium">
      <h2>VAPT:Cloud Security </h2>
      <div className="content-para">
        <p>
          Our 3 offerings SaaS, PaaS, and IaaS serve on the levels of software,
          platform and Infrastructures servicing but not limited to AWS, Azure,
          and Google Cloud. Approaching the cloud vulnerabilities and weaknesses
          brought by the many possible deployments via cloud requires expertise
          which FulSec team implements accordingly.
          <br />
          <br /> Our all in one plug n play model integrates data protection
          within our cyber security packages with customizable solutions to suit
          your business’ needs. Our full stack malware protection suite protects
          against advanced cyber attacks that could cause potential harm and
          cost money.
        </p>
      </div>
    </div>
  );
};

const Section = styled.div`
  display: block;
  width: 100%;
  height: 700px;

  background-color: #333;
  display: flex;
  justify-content: center;
  position: relative;

  @media screen and (max-width: 1200px) {
    height: 920px;
  }

  .testimonial {
    width: 70%;
    margin-top: 80px;
    @media screen and (max-width: 786px) {
      width: 300px;
    }
  }

  .testimonial .slick-arrow.slick-prev,
  .testimonial .slick-arrow.slick-next {
    background: #777;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    border-radius: 5px;
    @media screen and (max-width:786px ) {
      width: 20px;
      height: 20px;
      
    }
  }

  .slick-prev::before .slick-next::after {
    display: none;
  }

  .slick-arrow.slick-next {
    right: -70px;
    @media screen and (max-width: 768px) {
      right: -20px;
    }
 
  }
  .slick-arrow.slick-prev {
    left: -70px;
    @media screen and (max-width: 768px) {
      left: -20px;
    }
  }

  .content > h2 {
    font-size:35px;
    @media screen and (max-width: 786px) {
      font-size: 28px;
    }
  }
  .content-para {
    margin-top: 20px;
    font-size: 28px;
    @media screen and (max-width: 786px) {
      font-size: 18px;
    }
  }
  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    color: white;
  }
  .testimonial .slick-dots li button:before {
    font-size: 15px;
  }

  .testimonial > FaArrowLeft {
    color: gray;
    font-size: 45px;
  }
  .testimonial > FaArrowRight {
    color: gray;
    font-size: 45px;
  }

  .testimonial .slick-dots li {
    top: 15px;
  }
  .list > li {
    margin-top: 10px;
    color: #00bf76;
    font-weight: 600;
  }
  .list {
    margin-top: 10px;
  }

  #small {
    margin-top: 110px;
  }
  #medium {
    margin-top: 40px;
  }
`;

export default ServicesInfo;

import React, { useEffect } from "react";
// import image from "../../images/logo.jpeg";
import { FaBars } from "react-icons/fa";
import { useState } from "react";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavItem,
  NavLinks,
  NavMenu,
  NavBtn,
  NavBtnLink,
} from "./NavbarElements";
import Logo from "../../images/4-FULSEC_logo.png";
import useScrollListener from "../../hooks/useScrollListener"
import './styles.css'


const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);
  const handleEnter = (e) => {
    let keycode = e.keyCode ? e.keyCode : e.which;
    if (keycode == "13") {
      document.activeElement.click();
    }
  };


  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  const [navClassList, setNavClassList] = useState([]);
  const scroll = useScrollListener();

  // update classList of nav on scroll
  useEffect(() => {
    const _classList = [];

    if (scroll.y > 150 && scroll.y - scroll.lastY > 0)
      _classList.push("nav-bar--hidden");

    setNavClassList(_classList);
  }, [scroll.y, scroll.lastY]);


  return (
    <>
      <Nav scrollNav={scrollNav} className={navClassList.join(" ")} >
        <NavbarContainer>
          <NavLogo to="home" tabIndex="0" onKeyPress={handleEnter}>
            <img src={Logo} />
          </NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinks to="about" tabIndex="0" onKeyPress={handleEnter}>
                About
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="achievements" tabIndex="0" onKeyPress={handleEnter}>
                Achievements
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="products" tabIndex="0" onKeyPress={handleEnter}>
                Products
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="services" tabIndex="0" onKeyPress={handleEnter}>
                Services
              </NavLinks>
            </NavItem>

            <NavItem>
              <NavLinks to="resources" tabIndex="0" onKeyPress={handleEnter}>
                Resources
              </NavLinks>
            </NavItem>
            

            <NavItem>
              <NavLinks to="partners" tabIndex="0" onKeyPress={handleEnter}>
                Partners
              </NavLinks>
            </NavItem>
            
            <NavItem>
              <NavLinks to="Blog" tabIndex="0" onKeyPress={handleEnter}>
                Blog
              </NavLinks>
            </NavItem>
          </NavMenu>
          <NavBtn>
            <NavBtnLink to="signup" tabIndex="0" onKeyPress={handleEnter}>
              Get a Quote
            </NavBtnLink>
          </NavBtn>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default Navbar;
